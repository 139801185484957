import React from 'react';
import ReactPlayer from 'react-player/lazy';

import { makeStyles, useMediaQuery } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
}));

const Video = ({
	url,
	playing = false,
	loop = false,
	muted = false,
	controls = false,
	width,
	height,
	feature,
}) => {
	const classes = useStyles();

	return (
		<ReactPlayer
			url={url}
			playing={playing || feature}
			loop={loop}
			muted={muted}
			width={width}
			height={height}
			controls={controls}
			className={classes.reactPlayer}
		/>
	);
};

export default Video;
